import React from 'react'
import { Link } from 'gatsby'
import * as styles from './Footer.module.css'
import logo from '../images/logo_typo.svg'


function Footer() {


    return (
        <div className={styles.footerContainer}>
            <div className={styles.footerWrapper}>
                <img src={logo} className={styles.footerLogo} alt="Logo"/>
                <div className={styles.footerMenu}>
                    <Link to="/impressum" className={styles.footerLink}>Impressum</Link>   
                    <Link to="/datenschutz" className={styles.footerLink}>Datenschutz</Link>    
                </div>
                <div className={styles.footerInfo}>
                    <p className={styles.infoP}>© 2021 Tim Winthagen</p> 
                    <p className={styles.infoP}>Design und Programmierung: Steven Schindel, Vettweiss</p>
                </div>

            </div>
        </div>
    )
}

export default Footer
