import React from "react"
import Layout from "../components/layout"
import Info from "../components/Info";
import FourZeroFour from "../components/FourZeroFour";


const errorPage = () => {
  return (
    <Layout>
        <FourZeroFour/>
        <Info />
    </Layout>
  )
}

export default errorPage