import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import * as styles from './Navigation.module.css'
import hamburger from '../images/hamburger.png'


function Navigation() {
    const data = useStaticQuery(graphql`
        query {
            configJson {
                navigation {
                    name
                    route
                }
            }
        }
    `)

    return (
        <div className={styles.navContain}>
            <div className={styles.masterHead}><h1>Physiotherapie Praxis Tim Winthagen</h1></div>
            <div className={styles.navlist}>

                <img src={hamburger} alt="Navigation Menubutton" className={styles.hamburger}/>

                { data.configJson.navigation.map(elem => {
                    return (
                        <Link 
                            to={elem.route} 
                            className={styles.navitem} 
                            activeClassName={styles.active} 
                            key={elem.name}>
                            <p className={styles.navFont}>{elem.name}</p>
                        </Link> )
                    })
                }    
            </div>
        </div>
    )
}

export default Navigation;





