import React from 'react';
import * as styles from './Info.module.css';
import { graphql, Link, useStaticQuery } from 'gatsby';


function Info() {
    const data = useStaticQuery(graphql`
        query {
            configJson {
                info {
                    ext
                    head
                    img
                    int
                    text
                    zeile1
                    zeile2
                    zeile3
                    link
                }
            }
        }
    `)
    
    const imgPath = '/images/';
    return (
        <div className={styles.mainContainer}>
            <div className={styles.wrapper}>
                <div className={styles.flexContainer}>
                    { data.configJson.info.map(info => {
                        const isInt = info.int;
                        const isExt = info.ext;
                        const isText = info.text;
                        return (<div key={info.head} className={styles.infoContainer}>
                                    <img className={styles.icon} src={`${imgPath}${info.img}`} alt={info.head}/>
                                    <h3>{info.head}</h3>
                                    <p>{info.zeile1}</p>
                                    <p>{info.zeile2}</p>
                                    <br />
                                    {isExt
                                    ? <a href={info.link} target="_blank" rel="noopener noreferrer" >{info.zeile3}</a> 
                                    : null}
                                    {isInt
                                    ? <Link to={info.link} className={styles.link} >{info.zeile3}</Link> 
                                    : null}
                                    {isText
                                    ? <p>{info.zeile3}</p>
                                    : null}
                                </div>)
                    })}
                </div>
                <hr/>
                <div className={styles.mobilecontainer}>
                    <p className={styles.mobile}>Sie erreichen uns auch unter unserer Mobilnummer: 0172 8455910.</p>
                </div>
            </div>
        </div>
    )
}

export default Info


